<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form">
    <app-dialog
      v-model="open"
      primary-action-text="vouchers.openDelay"
      secondary-action-text="navigation.cancel"
      title="vouchers.openDelay"
      :disabled="failed"
      :loading="loading"
      @submit="submitOpenDelay"
    >
      <p>{{ $t('vouchers.delay.infoOpen') }}</p>

      <app-alert
        v-if="showReversalWarning"
        color="info"
        :icon="false"
        outlined
        class="subtitle-1 mt-6"
      >
        <div class="semi-bold">{{ $t('pleaseNote') }}:</div>
        {{ $t('vouchers.delay.warningPurchaseReversal') }}
      </app-alert>

      <app-input-row class="mt-8 mt-sm-4">
        <app-checkbox
          v-model="confirmOpenDelay"
          label="vouchers.delay.confirmOpenDelay"
          hide-details
          required
        />
      </app-input-row>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.toVoucher"
      title="vouchers.delay.delayOpened"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </ValidationObserver>
</template>

<script>
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Vouchers/store';
import { PurchaseStatus, BackendStatus } from '@/modules/Vouchers/statics/purchaseStatus';

export default {
  name: 'OpenDelayDialog',

  mixins: [ShowDialogMixin],

  props: {
    voucher: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    confirmOpenDelay: false
  }),

  computed: {
    showReversalWarning() {
      if (!this.permissions.voucherDetails.showPurchaseReversalWarning) {
        return false;
      }

      return this.product.withPurchaseAssurance
        ? this.voucher.purchaseStatus === BackendStatus.PURCHASABLE
        : this.purchaseStatusGroups[PurchaseStatus.PURCHASED].includes(this.voucher.purchaseStatus);
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['openDelay']),

    async submitOpenDelay() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.loading = true;
      const { error } = await this.openDelay({
        voucherIdentifier: this.$route.params.id
      });
      this.loading = false;

      if (error) {
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
