<template>
  <app-tooltip text="csvExportInfo">
    <app-icon-btn
      :disabled="disabled"
      :loading="loading"
      class="my-n1"
      color="grey"
      icon="icon-download"
      size="15px"
      small
      v-bind="$attrs"
      @click="download"
    />
  </app-tooltip>
</template>

<script>
import OpenFileMixin from '@/mixins/OpenFileMixin';
import formatDate from '@/helper/filter/formatDate';

export default {
  name: 'CsvDownload',

  mixins: [OpenFileMixin],

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    filenamePrefix: {
      type: String,
      required: true
    },
    loadCsv: {
      type: Function,
      required: true
    }
  },

  methods: {
    async download() {
      this.loading = true;

      const { file, error } = await this.loadCsv();

      if (error) {
        this.loading = false;
        return;
      }

      this.triggerDownload(
        file,
        this.$t('csvExportFilename', {
          prefix: this.filenamePrefix,
          date: formatDate(new Date())
        }),
        true
      );

      this.loading = false;
    }
  }
};
</script>
