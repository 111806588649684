<template>
  <app-icon-btn
    class="ml-n2 mr-n2"
    color="button-link"
    @click="setQueryParam(queryParam.ACTION, queryParam.EDIT_COMMENT)"
  >
    <app-tooltip
      :disabled="isMobile"
      :text="comment ? 'vouchers.comment.deposited' : 'vouchers.comment.create'"
    >
      <icon-bookmark-full v-if="comment" />
      <icon-add-square v-else />
    </app-tooltip>
  </app-icon-btn>
</template>

<script>
export default {
  name: 'EditCommentBtn',

  props: {
    comment: {
      type: String,
      default: ''
    }
  }
};
</script>
