<template>
  <v-container>
    <v-row v-for="childVoucher in details.children" :key="childVoucher.id" class="pb-4">
      <v-col cols="12" class="caption">
        {{ childVoucher.type | formatVoucherType }}
      </v-col>
      <v-col cols="6" class="hide-overflow">
        <router-link :to="getVoucherRoute(childVoucher)">
          {{ childVoucher.numberToShow || $t('unprocessed.voucherNumberIsGenerated') }}
        </router-link>
      </v-col>

      <v-col cols="3" class="text-right">
        {{ childVoucher.voucherDate | formatDate }}
      </v-col>
      <v-col cols="3" class="text-right">
        {{ childVoucher.amount | formatCurrency }}
      </v-col>
    </v-row>

    <v-row v-if="details.children.length === 0">
      <v-col>{{ $t('vouchers.noChildVouchers') }}</v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TabChildVouchers',

  props: {
    details: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
