<template>
  <fixed-full-height-container :fix="show">
    <v-navigation-drawer
      id="app-side-drawer"
      :value="show"
      :class="{ 'elevation-5': true, mobile: isMobile }"
      app
      right
      :permanent="show"
      hide-overlay
      disable-resize-watcher
      touchless
    >
      <div id="scroll-target" class="overflow-y-auto d-flex flex-column" style="height: 100%">
        <div
          v-scroll:#scroll-target="onScroll"
          :class="{
            'flex-grow-1': true,
            'd-flex flex-column': !hasContent
          }"
        >
          <div :class="['actions', { 'has-offset': offsetTop }]">
            <app-icon-btn
              :class="{ 'side-drawer__close-btn': true }"
              :elevation="actionsElevation"
              fixed
              icon="mdi-close"
              size="20"
              @click="resetDetailedParams"
            />

            <copy-report-btn :elevation="actionsElevation" :custom-data="reportData" />
          </div>
          <slot>
            <v-container fluid pa-5 fill-height :class="{ 'flex-grow-1': !hasContent }">
              <app-error-alert v-if="error" />
              <v-row v-else justify="center">
                <app-spinner />
              </v-row>
            </v-container>
          </slot>
        </div>
      </div>
    </v-navigation-drawer>
  </fixed-full-height-container>
</template>

<script>
import CopyReportBtn from '@/shared/components/CopyReportBtn';
import FixedFullHeightContainer from '@/shared/components/FixedFullHeightContainer';

export default {
  name: 'AppSideDrawer',

  components: { CopyReportBtn, FixedFullHeightContainer },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    reportData: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    offsetTop: 0
  }),

  computed: {
    actionsElevation() {
      return this.offsetTop ? 6 : null;
    },
    hasContent() {
      return this.$slots.default;
    }
  },

  methods: {
    resetDetailedParams() {
      this.$router.push({
        params: { id: undefined, type: undefined },
        query: { ...this.$route.query }
      });
    },

    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    }
  }
};
</script>

<style lang="scss">
#app-side-drawer.v-navigation-drawer {
  width: calc(33.33% - 24px) !important;
  min-width: 450px;

  &.mobile {
    min-width: 100vw;
    max-height: unset !important;

    #scroll-target {
      padding-bottom: 50px;
    }

    // fix mobile bottom spacing in firefox
    @-moz-document url-prefix() {
      #scroll-target {
        margin-bottom: 2rem;
      }
    }
  }

  .actions {
    z-index: 200;

    &.has-offset .v-btn {
      background-color: var(--c-white);
    }
  }

  .side-drawer__close-btn {
    border-radius: 0;
    top: 0;
    left: 0;
    height: auto;
    margin-left: 12px;
    padding: 18px 8px 8px !important;

    .v-btn__content {
      display: flex;
    }
  }
}

/**
   use the following classes to animate the width of the page content with and without side drawer
*/
.content--control-width {
  max-width: 100%;
  transition: max-width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.content--shrinked {
  max-width: 66.66%;
  will-change: max-width;
}
</style>
