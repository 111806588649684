<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FixedFullHeightContainer',

  props: {
    fix: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    bodyScrollY: 0
  }),

  watch: {
    fix: {
      handler(value) {
        if (!this.isMobile) {
          return;
        }

        // prevent body scrolling if top layer is scrolled
        if (value) {
          this.bodyScrollY = window.scrollY;
          const body = document.body;
          body.style.position = 'fixed';
          body.style.top = `-${this.bodyScrollY}px`;
        } else {
          const body = document.body;
          const currentBodyScrollY = body.style.top;
          body.style.position = '';
          body.style.top = '';
          window.scrollTo(0, parseInt(currentBodyScrollY || '0') * -1);
        }
      },
      immediate: true
    }
  }
};
</script>
