<template>
  <div>
    <app-dialog
      v-model="open"
      v-bind="actionProps"
      title="vouchers.comment.myComment"
      :loading="loading"
      :disabled="!startDelete && comment === voucher.comment"
      @submit="onSubmit"
      @close="onCancel"
    >
      <template v-if="actionProps" #secondaryAction>
        <app-btn block outlined @click="onCancel">
          {{ $t(actionProps.secondaryActionText) }}
        </app-btn>
      </template>

      <template v-if="!voucher.comment || startEdit">
        <app-textarea v-model="comment" :rows="Math.max(5, comment.length / 50)" />
      </template>

      <template v-else-if="startDelete">
        {{ $t('vouchers.comment.deleteRequest') }}
      </template>

      <template v-else>
        <div class="comment">{{ comment }}</div>
        <v-row class="mt-2">
          <v-col cols="auto">
            <app-text-btn
              color="button-link"
              large
              prepend-icon="icon-pen"
              spacious
              title="edit"
              underlined
              @click="startEdit = true"
            />
          </v-col>
          <v-col cols="auto">
            <app-text-btn
              color="button-link"
              large
              prepend-icon="icon-trash-can"
              spacious
              title="delete"
              underlined
              @click="startDelete = true"
            />
          </v-col>
        </v-row>
      </template>
    </app-dialog>
  </div>
</template>

<script>
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import { mapActions, mapMutations } from 'vuex';
import { NAMESPACE } from '@/modules/Vouchers/store';

export default {
  name: 'EditCommentDialog',

  mixins: [ShowDialogMixin],

  props: {
    voucher: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    comment: '',
    startEdit: false,
    startDelete: false
  }),

  computed: {
    actionProps() {
      return this.startDelete
        ? {
            primaryActionText: 'navigation.yesDelete',
            secondaryActionText: 'navigation.noCancel'
          }
        : !this.voucher.comment || this.startEdit
        ? {
            primaryActionText: 'navigation.save',
            secondaryActionText: 'navigation.cancel'
          }
        : null;
    }
  },

  methods: {
    ...mapMutations(NAMESPACE, ['SET_DETAILED_VOUCHER']),

    ...mapActions(NAMESPACE, ['editComment']),

    async onSubmit() {
      this.loading = true;
      if (this.startDelete) {
        this.comment = '';
      }

      const { error } = await this.editComment({
        voucherIdentifier: this.$route.params.id,
        comment: this.comment
      });
      this.loading = false;

      if (error) {
        return;
      }

      this.SET_DETAILED_VOUCHER({ voucher: { ...this.voucher, comment: this.comment } });
      this.startEdit = false;
      this.startDelete = false;
      if (this.comment === '') {
        this.$emit('input', false);
      }
    },

    onCancel() {
      if (this.startDelete) {
        this.startDelete = false;
        return;
      }

      if (this.startEdit) {
        this.startEdit = false;
        return;
      }

      this.$emit('input', false);
    }
  },

  created() {
    this.comment = this.voucher.comment;
  }
};
</script>

<style scoped>
.comment {
  background-color: var(--c-grey-background);
  padding: 1.5rem 1rem;
  white-space: pre-line;
}
</style>
