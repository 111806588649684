<template>
  <app-dialog
    v-model="open"
    title="vouchers.balancing.balancingSummary"
    width="auto"
    max-width="700px"
  >
    <template v-if="balancedVouchers.length > 0">
      <p class="subtitle-1 mb-10">
        {{ $t('vouchers.balancing.followingBalanced') }}
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th>{{ $t('pos') }}</th>
            <th>{{ $t('case') }}</th>
            <th>{{ $t('vouchers.customNumber') }}</th>
            <th class="text-right">{{ $t('amount') }}</th>
          </tr>
        </thead>

        <tbody>
          <template v-for="(voucher, index) in balancedVouchersWithoutBalancingChild">
            <tr :key="voucher.id" class="semi-bold">
              <td>{{ index + 1 }}</td>
              <td>{{ voucher.type | formatVoucherType }}</td>
              <td>
                <app-text-btn
                  :title="voucher.numberToShow || '-'"
                  class="semi-bold"
                  underlined
                  @click="redirectToVoucher(voucher)"
                />
              </td>
              <td class="text-right">
                <span v-if="voucher.initialLeftoverAmount">
                  {{ voucher.initialLeftoverAmount | formatCurrency }}
                </span>
                <span v-else>{{ voucher.amount | formatCurrency }}</span>
              </td>
            </tr>

            <tr v-for="childVoucher in voucher.children" :key="childVoucher.id">
              <td></td>
              <td>- {{ childVoucher.type | formatVoucherType }}</td>
              <td>
                <app-text-btn
                  :title="childVoucher.numberToShow || '-'"
                  underlined
                  @click="redirectToVoucher(childVoucher)"
                />
              </td>
              <td class="text-right">- {{ childVoucher.amount | formatCurrency }}</td>
            </tr>

            <tr :key="'leftover_' + voucher.id">
              <td
                colspan="4"
                :class="{
                  'py-5 grey--text semi-bold text-right': true,
                  'pb-12': index + 1 !== balancedVouchers.length
                }"
              >
                <div class="d-flex justify-end">
                  <span class="mr-2">{{ $t('leftoverAmount') }}</span>
                  <span class="td--min-width">
                    {{ calculateVoucherLeftoverBeforeBalancing(voucher) | formatCurrency }}
                  </span>
                </div>
              </td>
            </tr>
          </template>

          <tr>
            <td colspan="4" class="semi-bold text-right">
              <div class="d-flex justify-end">
                <span class="mr-2">{{ $t('vouchers.sum') }}</span>
                <span class="td--min-width">
                  {{ totalLeftoverBeforeBalancing | formatCurrency }}
                </span>
              </div>
            </td>
          </tr>

          <tr>
            <td colspan="4" class="pa-0">
              <v-divider />
            </td>
          </tr>

          <template v-for="(balancingVoucher, index) in balancingVouchers">
            <tr :key="index">
              <td></td>
              <td class="semi-bold">{{ balancingVoucher.type | formatVoucherType }}</td>
              <td>
                <app-text-btn
                  :title="balancingVoucher.numberToShow || '-'"
                  class="semi-bold"
                  underlined
                  @click="redirectToVoucher(balancingVoucher)"
                />
              </td>
              <td class="text-right">- {{ balancingVoucher.amount | formatCurrency }}</td>
            </tr>
          </template>

          <tr>
            <td></td>
            <td>
              <app-tooltip text="vouchers.balancing.withholdInfo">
                <span class="d-flex align-center">
                  {{ $t('vouchers.balancing.withhold') }}
                  <icon-info class="ml-2 icon--inline grey--text" />
                </span>
              </app-tooltip>
            </td>
            <td></td>
            <td class="text-right">- {{ witholdAmount | formatCurrency }}</td>
          </tr>

          <tr>
            <td colspan="4" class="pa-0">
              <v-divider />
            </td>
          </tr>

          <tr>
            <td colspan="4">
              <span class="d-flex align-center justify-end semi-bold">
                {{ $t('vouchers.balancing.balanced') }}
                <icon-check-with-circle class="primary--text ml-2" />
              </span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-row justify="start" class="my-2">
        <v-col cols="6">
          <app-btn block @click="open = false">
            {{ $t('navigation.backToVoucher') }}
          </app-btn>
        </v-col>
      </v-row>
    </template>

    <v-row v-else justify="center" class="my-2">
      <v-col cols="auto">
        <app-spinner />
      </v-col>
    </v-row>
  </app-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Vouchers/store';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';

export default {
  name: 'BalancingSummary',

  mixins: [ShowDialogMixin],

  data: () => ({
    balancingVouchers: [],
    balancedVouchers: []
  }),

  computed: {
    balancedVouchersWithoutBalancingChild() {
      return this.balancedVouchers.map((voucher) => ({
        ...voucher,
        children: voucher.children.filter((child) => !child.isBalancingVoucher)
      }));
    },
    totalLeftoverBeforeBalancing() {
      return this.balancedVouchersWithoutBalancingChild.reduce(
        (totalLeftover, voucher) =>
          totalLeftover + this.calculateVoucherLeftoverBeforeBalancing(voucher),
        0
      );
    },
    witholdAmount() {
      return this.balancingVouchers.reduce(
        (balancingLeftover, balancingVoucher) => balancingLeftover - balancingVoucher.amount,
        this.totalLeftoverBeforeBalancing
      );
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchBalancingSummary']),

    redirectToVoucher(voucher) {
      this.$router.push({
        ...this.getVoucherRoute(voucher),
        query: { ...this.$route.query }
      });

      this.$emit('input', false);
    },

    calculateVoucherLeftoverBeforeBalancing(voucher) {
      return voucher.children.reduce(
        (voucherLeftover, child) => voucherLeftover - child.amount,
        voucher.initialLeftoverAmount ? voucher.initialLeftoverAmount : voucher.amount
      );
    }
  },

  async created() {
    const { balancedVouchers, balancingVouchers, error } = await this.fetchBalancingSummary(
      this.$route.params.id
    );

    if (error) {
      return;
    }
    this.balancedVouchers = balancedVouchers;
    this.balancingVouchers = balancingVouchers;
  }
};
</script>

<style lang="scss" scoped>
.theme--light.v-data-table {
  tbody tr {
    &:hover {
      background: none !important;
    }

    td {
      height: 2rem;
      max-width: 200px;
      line-height: 24px;
      font-size: 16px;
      border: none !important;
    }
  }

  thead + tbody tr:first-child td {
    padding-top: 1rem;
  }

  thead tr th {
    height: 2rem;
    color: var(--c-text);
    border-color: var(--c-border);
  }

  hr.v-divider {
    border-color: var(--c-border);
  }

  .td--min-width {
    min-width: 100px;
  }
}
</style>
