<template>
  <div>
    <app-dialog
      v-model="open"
      :primary-action-text="isMobile ? 'navigation.confirm' : 'vouchers.cancelDunningBlock'"
      secondary-action-text="navigation.cancel"
      title="vouchers.cancelDunningBlock"
      text="vouchers.dunningBlock.infoCancel"
      :loading="loading"
      @submit="submitDunningBlock"
    />

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.toVoucher"
      title="vouchers.dunningBlock.blockCancelled"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </div>
</template>

<script>
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Vouchers/store';

export default {
  name: 'CancelDunningBlockDialog',

  mixins: [ShowDialogMixin],

  props: {
    voucher: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['cancelDunningBlock']),

    async submitDunningBlock() {
      this.loading = true;
      const { error } = await this.cancelDunningBlock(this.$route.params.id);
      this.loading = false;

      if (error) {
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
