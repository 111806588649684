<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <history :data="history" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import History from '@/shared/components/History';

export default {
  name: 'TabVoucherHistory',

  components: {
    History
  },

  props: {
    history: {
      type: Array,
      default: () => []
    }
  }
};
</script>
