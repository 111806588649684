<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form">
    <app-dialog
      v-model="open"
      primary-action-text="vouchers.closeDelay"
      secondary-action-text="navigation.cancel"
      title="vouchers.closeDelay"
      :disabled="failed"
      :loading="loading"
      @submit="submitCloseDelay"
    >
      <p>{{ $t('vouchers.delay.infoClose') }}</p>

      <app-input-row class="mt-8 mt-sm-4">
        <app-checkbox
          v-model="confirmCloseDelay"
          label="vouchers.delay.confirmCloseDelay"
          hide-details
          required
        />
      </app-input-row>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.toVoucher"
      title="vouchers.delay.delayClosed"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </ValidationObserver>
</template>

<script>
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Vouchers/store';

export default {
  name: 'CloseDelayDialog',

  mixins: [ShowDialogMixin],

  props: {
    voucher: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    confirmCloseDelay: false
  }),

  methods: {
    ...mapActions(NAMESPACE, ['closeDelay']),

    async submitCloseDelay() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.loading = true;
      const { error } = await this.closeDelay({
        voucherIdentifier: this.$route.params.id
      });
      this.loading = false;

      if (error) {
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
