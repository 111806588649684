import { render, staticRenderFns } from "./DetailedWorkQueueAlert.vue?vue&type=template&id=0e9b8736&"
import script from "./DetailedWorkQueueAlert.vue?vue&type=script&lang=js&"
export * from "./DetailedWorkQueueAlert.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports