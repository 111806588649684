<template>
  <app-alert v-if="queue.length > 0">
    <div class="error--text mb-2">
      {{ queue[0].title }}
    </div>

    {{ $t('vouchers.openWorkQueueInfo') }}
    <div>
      <app-btn
        class="mt-8 align-self-end"
        :to="{ name: routeName.WORK_QUEUE, params: { id: queue[0].id } }"
      >
        {{ $t('navigation.toWorkQueue') }}
      </app-btn>
    </div>
  </app-alert>
</template>

<script>
export default {
  name: 'DetailedWorkQueueAlert',

  props: {
    queue: {
      type: Array,
      required: true
    }
  }
};
</script>
