import formatCurrency from '@/helper/filter/formatCurrency';

export default {
  computed: {
    history() {
      if (!this.details.history) {
        return;
      }

      return this.mapHistory(this.details.history);
    }
  },

  methods: {
    mapHistory(data) {
      return data
        .map(({ event, text, metadata, ...item }) => ({
          ...item,
          event,
          text: this.eventTranslation(event, text, metadata)
        }))
        .filter((event) => !!event.text);
    },

    snakeToCamel(str) {
      return str.replace(/([-_][a-z])/g, (group) =>
        group.toUpperCase().replace('-', '').replace('_', '')
      );
    },

    eventTranslation(event, text, metadata) {
      event = this.snakeToCamel(event);
      let formattedMetadata = { ...metadata };

      if (event === 'approvedLimitWasUpdated') {
        formattedMetadata.limit = formatCurrency(metadata.limit || 0);
      }

      if (text) {
        return text;
      }

      const productDependentTranslationPath = `historyEvents.${
        this.isFactoring ? 'factoring' : 'billing'
      }.${event}`;

      if (this.$te(productDependentTranslationPath)) {
        return this.$t(productDependentTranslationPath, formattedMetadata);
      }

      if (this.$te(`historyEvents.${event}`)) {
        return this.$t(`historyEvents.${event}`, formattedMetadata);
      }

      return '';
    }
  }
};
