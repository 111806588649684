<template>
  <div>
    <app-data-table
      :class="{ 'mb-12': !isMobile }"
      :error="listError"
      :expanded="expanded"
      :headers="tableHeaders"
      :items="formattedTableData"
      :loading="loadingVouchers || !initiated"
      :total-data-length="count"
    >
      <template #title>
        <div class="table-title__content">
          <div :class="classesTitleLeft">
            <div class="d-flex align-baseline">
              {{ $tc('vouchersCounted', count) }}
              <slot name="csv-download"></slot>
            </div>
            <div
              v-if="unprocessedVouchersCount > 0"
              :class="{ 'caption semi-bold d-flex': true, 'mt-2': count === 0 }"
            >
              ({{ $tc('vouchers.moreVouchersCounted', unprocessedVouchersCount) }}&nbsp;
              <app-link-btn
                v-if="permissions.navigation[routeName.UNPROCESSED]"
                :to="{ name: routeName.UNPROCESSED }"
                class="caption semi-bold"
                title="vouchers.processing"
              />
              <span v-else>{{ $t('vouchers.processing') }}</span>
              )
            </div>
          </div>
          <div v-if="!voucherAmountsError" :class="`${classesTitleRight}`">
            <creditor-limits-menu v-if="creditorLimits" :creditor-limits="creditorLimits">
              <app-spinner
                v-if="!voucherAmounts && permissions.vouchers.sum"
                color="secondary"
                size="24"
                class="mx-1"
              />
              <template v-else-if="permissions.vouchers.sum">
                {{ $t('vouchers.sum') }}:
                <span :class="classesTitleRightValue">
                  {{ voucherAmounts.total | formatCurrency }}
                </span>
                <div class="grey--text">
                  {{ $t('vouchers.leftoverAmount') }}:
                  <span :class="`${classesTitleRightValue} font-weight-medium`">
                    {{ voucherAmounts.leftOver | formatCurrency }}
                  </span>
                </div>
              </template>
            </creditor-limits-menu>
          </div>
        </div>
      </template>

      <template #item="{ item }">
        <app-table-row
          :item="item"
          :bordered="item.hasWorkQueue && permissions.voucherDetails.openWorkQueue"
          :highlighted="isShownInDetails(item.id)"
          @click="setDetailedParams(item.id)"
        >
          <app-table-data :sorted="isSorted(0)" :header="tableHeaders[0]">
            <div class="d-flex align-center">
              <span
                v-if="hasStatusFailed(item)"
                :class="{ 'mr-2': true, 'error--text': !isShownInDetails(item.id) }"
              >
                <icon-alert />
              </span>
              {{ item.statusTrans }}
              {{ suffix(item).length > 0 ? `- ${suffix(item)}` : '' }}
            </div>
            <div v-if="!hasStatusClosed(item)">
              <app-status-chip :value="item.subStatus ? item.subStatus : 'accepted'" />
            </div>
          </app-table-data>
          <app-table-data :sorted="isSorted(1)" :header="tableHeaders[1]">
            <div>
              {{ item.location ? item.location.name : item.debtor ? item.debtor.name : '-' }}
            </div>
            <div class="subtitle-1 grey--text">
              {{ item.location ? item.location.customNumber : '-' }}
            </div>
          </app-table-data>
          <app-table-data :sorted="isSorted(2)" :header="tableHeaders[2]">
            {{ item.numberToShow || '-' }}
            <div class="subtitle-1 grey--text">{{ item.type | formatVoucherType }}</div>
          </app-table-data>
          <app-table-data :sorted="isSorted(3)" :header="tableHeaders[3]">
            {{ item.voucherDate | formatDate }}
            <div v-if="hasAdminView" class="subtitle-1 grey--text">
              {{ item.uploaded | formatDateTime }}
            </div>
            <div v-else class="subtitle-1 grey--text">{{ item.uploaded | formatDate }}</div>
          </app-table-data>
          <app-table-data :sorted="isSorted(4)" :header="tableHeaders[4]">
            <div>
              {{ item.paymentDeadline | formatDate }}
            </div>
            <div
              v-if="
                !hasStatusClosed(item) &&
                item.paymentDeadline &&
                calculateDaysOverdue(item.paymentDeadline) > -7
              "
              :class="`subtitle-1 ${getOverdueClass(item)}`"
            >
              {{ getCountedDueDays(item) }}
            </div>
          </app-table-data>
          <app-table-data :sorted="isSorted(5)" :header="tableHeaders[5]">
            <div>
              <div class="semi-bold">{{ item.amount | formatCurrency }}</div>
              <div v-if="item.isInvoice" class="subtitle-1 grey--text">
                {{ item.leftoverAmount | formatCurrency }}
              </div>
            </div>
          </app-table-data>
          <app-table-data
            v-if="!isMobile || (isMobile && item.children.length > 0)"
            :class="{ 'justify-center': isMobile }"
          >
            <app-icon-btn
              v-if="item.children.length > 0"
              :icon="
                expanded.find((voucher) => voucher.id === item.id)
                  ? 'mdi-chevron-up'
                  : 'mdi-chevron-down'
              "
              large
              @click.stop="expandRow(item)"
            />
          </app-table-data>
        </app-table-row>
      </template>

      <template #expanded-item="{ item }">
        <template v-for="(child, index) in item.children">
          <app-table-row
            :key="child.id"
            :item="item"
            :highlighted="isShownInDetails(child.id)"
            expanded
            :class="{
              last: item.children.length === index + 1,
              first: index === 0
            }"
            @click="setDetailedSubParams(child.id)"
          >
            <app-table-data :sorted="isSorted(0)" :header="tableHeaders[0]">
              <v-row no-gutters class="flex-nowrap" align="center">
                <v-col v-if="!isMobile" cols="auto" class="body-1 align-self-baseline">
                  <div class="dot__wrapper">
                    <icon-dot />
                  </div>
                </v-col>
                <v-col cols="auto">
                  {{ child.type | formatVoucherType }}
                </v-col>
              </v-row>
            </app-table-data>
            <app-table-data :sorted="isSorted(1)" :header="tableHeaders[1]" />
            <app-table-data :sorted="isSorted(2)" :header="tableHeaders[2]">
              {{ child.numberToShow || '-' }}
            </app-table-data>
            <app-table-data :sorted="isSorted(3)" :header="tableHeaders[3]">
              {{ child.voucherDate | formatDate }}
              <div class="subtitle-1 grey--text">
                <template v-if="hasAdminView">{{ child.uploaded | formatDateTime }}</template>
                <template v-else>{{ child.uploaded | formatDate }}</template>
              </div>
            </app-table-data>
            <app-table-data :sorted="isSorted(4)" :header="tableHeaders[4]" />
            <app-table-data class="semi-bold" :sorted="isSorted(5)" :header="tableHeaders[5]">
              {{ child.amount | formatCurrency }}
            </app-table-data>
            <app-table-data />
            <v-divider v-if="isMobile && index + 1 < item.children.length" class="mx-4" />
          </app-table-row>
        </template>
      </template>

      <template #no-data>
        <div v-if="empty" class="mx-4">
          <template v-if="permissions.vouchers.upload">
            <h2 class="mb-8">{{ $t('vouchers.noData') }}</h2>
            <p class="mb-10" style="max-width: 600px">{{ $t('vouchers.noDataInfo') }}</p>
            <app-btn @click="openVoucherUploadDialog">
              {{ $t('vouchers.uploadFirst') }}
            </app-btn>
          </template>
          <p v-else>{{ $t('vouchers.noData') }}</p>
        </div>
        <div v-else>
          {{ $t('noMatchingResults') }}
        </div>
      </template>
    </app-data-table>

    <app-api-log v-if="hasAdminView" :data="vouchers" api-name="/voucher/list" />
  </div>
</template>

<script>
import CreditorLimitsMenu from '@/modules/Vouchers/components/CreditorLimitsMenu';
import PaymentDeadlineMixin from '@/mixins/PaymentDeadlineMixin';
import PurchaseStatus from '@/modules/Vouchers/statics/purchaseStatus';
import StatusMixin from '@/mixins/StatusMixin';
import TableMixin from '@/mixins/TableMixin';
import VoucherStatusToShow from '@/statics/voucherStatusToShow';
import VoucherSubStatus from '@/statics/voucherSubStatus';
import formatVoucherStatusToShow from '@/helper/filter/formatVoucherStatusToShow';
import { NAMESPACE } from '@/modules/Vouchers/store';
import { mapState } from 'vuex';

export default {
  name: 'VouchersTable',

  mixins: [TableMixin, StatusMixin, PaymentDeadlineMixin],

  components: {
    CreditorLimitsMenu
  },

  data() {
    return {
      tableHeaders: [
        { text: this.$t('status'), value: 'status', sortable: false },
        { text: this.$t('debtor'), value: 'location', alignLeft: true },
        { text: this.$t('vouchers.customNo'), value: 'numberToShow' },
        {
          text: this.$t('vouchers.voucherDate'),
          subText: this.$t('vouchers.uploadDate'),
          value: 'voucherDate',
          sortMobile: true
        },
        { text: this.$t('vouchers.paymentDeadline'), value: 'paymentDeadline', sortMobile: true },
        {
          text: this.$t('amount'),
          subText: this.$t('vouchers.leftover'),
          value: 'amount',
          sortMobile: true
        },
        {
          text: null,
          value: 'data-table-expand',
          sortable: false,
          width: '70px'
        }
      ],
      expanded: [],
      loading: false
    };
  },

  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 0
    },
    empty: {
      type: Boolean,
      default: false
    },
    initiated: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState(NAMESPACE, [
      'vouchers',
      'listError',
      'voucherAmounts',
      'voucherAmountsError',
      'loadingVouchers',
      'loadingDetails',
      'creditorLimits',
      'unprocessedVouchersCount'
    ]),

    formattedTableData() {
      // return already formatted table data content for correct sorting
      return JSON.parse(JSON.stringify(this.tableData)).map((entry) => ({
        ...entry,
        statusTrans: formatVoucherStatusToShow(entry.statusToShow)
      }));
    }
  },

  watch: {
    loadingVouchers() {
      this.updateExpanded();
    },
    loadingDetails() {
      this.updateExpanded();
    }
  },

  methods: {
    getOverdueClass(item) {
      const useHighlightColor = this.isShownInDetails(item.id);

      if (item.subStatus === VoucherSubStatus.HALT) {
        return useHighlightColor ? 'table-highlighted-text-light--text' : 'grey--text';
      }
      if (this.calculateDaysOverdue(item.paymentDeadline) >= 7) {
        return useHighlightColor ? 'table-highlighted-error--text' : 'error--text';
      }
      if (this.calculateDaysOverdue(item.paymentDeadline) > 0) {
        return useHighlightColor ? 'table-highlighted-warning--text' : 'warning--text';
      }

      return 'grey--text';
    },

    suffix(item) {
      if (
        item.statusToShow !== VoucherStatusToShow.CLOSED ||
        !this.purchaseStatusGroups[PurchaseStatus.PURCHASED].includes(item.purchaseStatus) ||
        !item.isInvoice
      ) {
        return '';
      }

      return this.$t(`vouchers.purchaseStatusOptions.${PurchaseStatus.PURCHASED}`);
    },

    updateExpanded() {
      if (
        this.loadingVouchers ||
        this.loadingDetails ||
        !this.$route.params.id ||
        this.$route.params.type !== 'sub' ||
        this.expanded.length > 0
      ) {
        return;
      }

      const detailedVoucher = this.tableData.find((item) =>
        item.children.some((child) => child.id === this.$route.params.id)
      );

      if (detailedVoucher) {
        this.expanded.push(detailedVoucher);
      }
    }
  }
};
</script>
