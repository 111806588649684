<template>
  <app-icon-btn
    :elevation="isMobile ? 12 : 4"
    class="add-btn"
    fab
    icon="icon-plus"
    size="24"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'AddBtn'
};
</script>

<style scoped lang="scss">
.add-btn.add-btn {
  background-color: var(--c-button-fab-background);
  color: var(--c-button-fab-text);
  position: absolute;
  top: 2rem;
  right: 0;
}

.v-application.mobile .add-btn {
  position: fixed;
  top: unset;
  right: 1rem;
  bottom: 2rem;
  z-index: 5;
}
</style>
