<template>
  <app-select
    filter
    :items="states"
    :label="$t('status')"
    label-icon="icon-gauge"
    multiple
    hide-details
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'StatusSelect',

  computed: {
    states() {
      const statusOptions = [
        ...this.product.voucherStatusToShowGroups.open,
        ...this.product.voucherSubStatusFilter,
        ...this.product.voucherStatusToShowGroups.closed,
        ...this.product.voucherStatusToShowGroups.failed
      ];
      return statusOptions.map((status) => ({
        label: this.$te(`vouchers.statusToShowOptions.${status}`)
          ? this.$t(`vouchers.statusToShowOptions.${status}`)
          : this.$t(`vouchers.subStatusOptions.${status}`),
        value: status
      }));
    }
  }
};
</script>
