<template>
  <app-alert color="primary" icon="$iconInfo">
    {{ $t('vouchers.balancing.balancedInfo') }}
    <app-link-btn
      class="semi-bold d-block mt-4"
      title="vouchers.balancing.showBalancingSummary"
      @click="setQueryParam(queryParam.ACTION, queryParam.SHOW_BALANCING_SUMMARY)"
    />
  </app-alert>
</template>

<script>
export default {
  name: 'BalancingAlert'
};
</script>
