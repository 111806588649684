<template>
  <v-menu :open-on-hover="!isMobile" left top offset-y nudge-top="20" min-width="350px">
    <template #activator="{ on }">
      <div v-on="isFactoring ? on : null">
        <icon-info
          v-if="isFactoring"
          height="16px"
          width="16px"
          style="position: relative; top: 0.125rem"
        />
        <slot></slot>
      </div>
    </template>
    <v-list dense class="px-2">
      <v-list-item
        v-for="(item, index) in creditorLimitItems"
        :key="index"
        style="min-height: 28px"
      >
        <v-list-item-content class="py-0">
          <v-list-item-title class="caption grey--text d-flex justify-space-between">
            {{ item.text }}
            <span class="ml-1 text-right text--text">{{ item.value }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import formatCurrency from '@/helper/filter/formatCurrency';

export default {
  name: 'CreditorLimitsMenu',

  props: {
    creditorLimits: {
      type: Object,
      required: true
    }
  },

  computed: {
    creditorLimitItems() {
      return [
        {
          text: this.$t('debtors.approvedCreditorLimit'),
          value: formatCurrency(this.creditorLimits.approvedLimit)
        },
        ...(this.permissions.debtors.showSumOfPurchasedInvoices
          ? [
              {
                text: this.$t('debtors.sumPurchasedInvoices'),
                value: `- ${formatCurrency(
                  this.creditorLimits.approvedLimit - this.creditorLimits.availableLimit
                )}`
              }
            ]
          : []),
        {
          text: this.$t('debtors.availableCreditorLimit'),
          value: formatCurrency(this.creditorLimits.availableLimit)
        }
      ];
    }
  }
};
</script>
