<template>
  <app-autocomplete
    filter
    :items="options"
    :label="$t('bnetIdentifier')"
    hide-details
    multiple
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { mapState } from 'vuex';
import { NAMESPACE } from '@/modules/Vouchers/store';

export default {
  name: 'BnetIdentifierSelect',

  computed: {
    ...mapState(NAMESPACE, { items: 'bnetIdentifiers' }),
    options() {
      return [...new Set(this.items.map(({ bnetIdentifier }) => bnetIdentifier))];
    }
  }
};
</script>
