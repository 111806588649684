<template>
  <div>
    <app-dialog
      v-model="open"
      :primary-action-text="isMobile ? 'navigation.confirm' : 'vouchers.setDunningBlock'"
      secondary-action-text="navigation.cancel"
      title="vouchers.setDunningBlock"
      :disabled="!confirmDunningBlock"
      :loading="loading"
      @submit="submitDunningBlock"
    >
      <template v-if="isBilling && !product.withPurchaseAssurance">
        <p>{{ $t('vouchers.dunningBlock.infoSetBilling') }}</p>

        <app-alert color="info" :icon="false" outlined class="subtitle-1 mt-6">
          <div class="semi-bold">{{ $t('pleaseNote') }}:</div>
          {{ $t('vouchers.dunningBlock.warningBilling') }}
        </app-alert>
      </template>

      <template v-else-if="voucher.dunningBlockCount === 0">
        <p>{{ $t('vouchers.dunningBlock.infoSet') }}</p>

        <app-alert
          v-if="showReversalWarning"
          color="info"
          :icon="false"
          outlined
          class="subtitle-1 mt-6"
        >
          <div class="semi-bold">{{ $t('pleaseNote') }}:</div>
          {{ $t('vouchers.dunningBlock.warningPurchaseReversal') }}
        </app-alert>
      </template>

      <template v-else>
        <p>{{ $t('vouchers.dunningBlock.infoSetAgain') }}</p>

        <app-alert
          v-if="showReversalWarning"
          color="info"
          :icon="false"
          outlined
          class="subtitle-1 mt-6"
        >
          <div class="semi-bold">{{ $t('pleaseNote') }}:</div>
          {{ $t('vouchers.dunningBlock.warningPurchaseReversalSetAgain') }}
        </app-alert>
      </template>

      <app-input-row class="mt-4">
        <app-checkbox
          v-model="confirmDunningBlock"
          label="vouchers.dunningBlock.approveDunningBlock"
          hide-details
        />
      </app-input-row>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.toVoucher"
      title="vouchers.dunningBlock.blockSet"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </div>
</template>

<script>
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Vouchers/store';
import { PurchaseStatus, BackendStatus } from '@/modules/Vouchers/statics/purchaseStatus';

export default {
  name: 'SetDunningBlockDialog',

  mixins: [ShowDialogMixin],

  props: {
    voucher: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    confirmDunningBlock: false
  }),

  computed: {
    showReversalWarning() {
      if (!this.permissions.voucherDetails.showPurchaseReversalWarning) {
        return false;
      }

      return this.product.withPurchaseAssurance
        ? this.voucher.purchaseStatus === BackendStatus.PURCHASABLE
        : this.purchaseStatusGroups[PurchaseStatus.PURCHASED].includes(this.voucher.purchaseStatus);
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['setDunningBlock']),

    async submitDunningBlock() {
      this.loading = true;
      const { error } = await this.setDunningBlock(this.$route.params.id);
      this.loading = false;

      if (error) {
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
