<template>
  <v-container fluid class="pa-0">
    <add-btn v-if="permissions.vouchers.upload" @click="openVoucherUploadDialog" />
    <vouchers-filter />
    <work-queue-alert v-if="!isMobile && permissions.vouchers.openWorkQueue" />

    <vouchers-table
      :initiated="startedInitialFetch"
      :table-data="vouchers"
      :count="vouchersCount"
      :empty="vouchersEmpty"
      :class="{ 'content--control-width': true, 'content--shrinked': showDetailsDrawer }"
    >
      <template #csv-download>
        <csv-download
          :disabled="vouchers.length === 0"
          :filename-prefix="$t('voucher')"
          :load-csv="loadCSV"
        />
      </template>
    </vouchers-table>

    <app-side-drawer :show="showDetailsDrawer" :error="detailsError" :report-data="reportData">
      <component
        :is="
          $route.params.type === 'sub' || !detailedVoucher.isInvoice
            ? 'child-voucher-details'
            : 'voucher-details'
        "
        v-if="fetchedDetailedVoucher"
        :details="detailedVoucher"
        :voucher-actions="actions"
      />
    </app-side-drawer>

    <component
      :is="selectedAction.component"
      v-if="fetchedDetailedVoucher"
      v-model="showActionDialog"
      :voucher="detailedVoucher"
      @success="reloadDetails($route.query)"
    />
  </v-container>
</template>

<script>
import AbandonmentDialog from '@/modules/Vouchers/components/Actions/AbandonmentDialog';
import ActionMixin from '@/mixins/ActionMixin';
import AddBtn from '@/shared/components/AddBtn';
import AnnulationDialog from '@/modules/Vouchers/components/Actions/AnnulationDialog';
import AppSideDrawer from '@/shared/components/AppSideDrawer';
import BalancingSummary from '@/modules/Vouchers/components/Actions/BalancingSummary';
import CancelDunningBlockDialog from '@/modules/Vouchers/components/Actions/CancelDunningBlockDialog';
import ChildVoucherDetails from '@/modules/Vouchers/components/Details/ChildVoucherDetails';
import CloseDisputeDialog from '@/modules/Vouchers/components/Actions/CloseDisputeDialog';
import CloseDelayDialog from '@/modules/Vouchers/components/Actions/CloseDelayDialog';
import CreditDialog from '@/modules/Vouchers/components/Actions/CreditDialog';
import CsvDownload from '@/shared/components/CsvDownload';
import DirectPaymentDialog from '@/modules/Vouchers/components/Actions/DirectPaymentDialog';
import EditCommentDialog from '@/modules/Vouchers/components/Actions/EditCommentDialog';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import OpenDisputeDialog from '@/modules/Vouchers/components/Actions/OpenDisputeDialog';
import OpenDelayDialog from '@/modules/Vouchers/components/Actions/OpenDelayDialog';
import SetDunningBlockDialog from '@/modules/Vouchers/components/Actions/SetDunningBlockDialog';
import VoucherDetails from '@/modules/Vouchers/components/Details/VoucherDetails';
import VouchersFilter from '@/modules/Vouchers/components/Filter/VouchersFilter';
import VouchersTable from '@/modules/Vouchers/components/VouchersTable';
import WorkQueueAlert from '@/modules/Vouchers/components/WorkQueueAlert';
import formatVoucherType from '@/helper/filter/formatVoucherType';
import { INVOICE_TYPES } from '@/statics/voucherType';
import { NAMESPACE } from '@/modules/Vouchers/store';
import { QueryParam } from '@/statics/queryParam';
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex';
import DunningType from '@/statics/dunningType';

export default {
  name: 'Vouchers',

  components: {
    CsvDownload,
    AddBtn,
    VouchersFilter,
    VouchersTable,
    VoucherDetails,
    ChildVoucherDetails,
    AppSideDrawer,
    WorkQueueAlert
  },

  mixins: [ActionMixin, ListFilterMixin],

  data: () => ({
    startedInitialFetch: false,
    queryValues: [
      QueryParam.PAGE,
      QueryParam.ITEMS_PER_PAGE,
      QueryParam.SORT_BY,
      QueryParam.SORT_DESC,
      'bnetIdentifier',
      'encashmentFileNumber',
      'customNumber',
      'location',
      'dueDate',
      'uploadDate',
      'voucherDate',
      'purchaseStatus',
      'status',
      'type'
    ],
    defaultSorting: {
      sortBy: ['uploadDate'],
      sortDesc: [true]
    }
  }),

  computed: {
    ...mapState(NAMESPACE, [
      'vouchers',
      'vouchersCount',
      'vouchersEmpty',
      'detailedVoucher',
      'loadingVouchers',
      'loadingDetails',
      'detailsError'
    ]),
    ...mapGetters(NAMESPACE, [
      'identifiersByNumbers',
      'identifiersByBnetIdentifiers',
      'identifiersByEncashmentFileNumbers'
    ]),
    actions() {
      return [
        {
          param: this.queryParam.DIRECT_PAYMENT,
          component: DirectPaymentDialog,
          icon: 'icon-euro',
          text: this.$t('vouchers.reportDirectPayment'),
          callback: () =>
            this.setQueryParam(this.queryParam.ACTION, this.queryParam.DIRECT_PAYMENT),
          permission: this.openDebtRemaining && this.detailedVoucher.permissions.directPayment,
          condition: this.permissions.voucherDetails.directPayment
        },
        {
          param: this.queryParam.CREDIT_ADVICE,
          component: CreditDialog,
          icon: 'icon-credit',
          text: this.$t('vouchers.generateCredit'),
          callback: () => this.setQueryParam(this.queryParam.ACTION, this.queryParam.CREDIT_ADVICE),
          permission: this.openDebtRemaining && this.detailedVoucher.permissions.creditAdvice,
          condition: this.permissions.voucherDetails.creditAdvice
        },
        {
          param: this.queryParam.ANNULATION,
          component: AnnulationDialog,
          icon: 'icon-delete-file',
          text: this.$t('vouchers.executeInvoiceAnnulation'),
          callback: () => this.setQueryParam(this.queryParam.ACTION, this.queryParam.ANNULATION),
          permission:
            this.detailedVoucher.children.length === 0 &&
            this.detailedVoucher.permissions.annulation,
          condition: this.permissions.voucherDetails.annulation
        },
        {
          param: this.queryParam.ABANDONMENT,
          component: AbandonmentDialog,
          icon: 'icon-wallet',
          text: this.$t('vouchers.abandon'),
          callback: () => this.setQueryParam(this.queryParam.ACTION, this.queryParam.ABANDONMENT),
          permission: this.openDebtRemaining && this.detailedVoucher.permissions.abandonment,
          condition: this.permissions.voucherDetails.abandonment
        },
        {
          param: this.queryParam.SET_DUNNING_BLOCK,
          component: SetDunningBlockDialog,
          icon: 'icon-block',
          text: this.$t('vouchers.setDunningBlock'),
          callback: () =>
            this.setQueryParam(this.queryParam.ACTION, this.queryParam.SET_DUNNING_BLOCK),
          permission: this.openDebtRemaining && this.detailedVoucher.permissions.setDunningBlock,
          condition:
            !this.detailedVoucherIsBlocked && this.permissions.voucherDetails.setDunningBlock
        },
        {
          param: this.queryParam.CANCEL_DUNNING_BLOCK,
          component: CancelDunningBlockDialog,
          icon: 'icon-block',
          text: this.$t('vouchers.cancelDunningBlock'),
          callback: () =>
            this.setQueryParam(this.queryParam.ACTION, this.queryParam.CANCEL_DUNNING_BLOCK),
          active: true,
          permission: this.detailedVoucher.permissions.cancelDunningBlock,
          condition:
            this.detailedVoucherIsBlocked && this.permissions.voucherDetails.cancelDunningBlock
        },
        {
          param: this.queryParam.OPEN_DISPUTE,
          component: OpenDisputeDialog,
          icon: 'icon-dispute',
          text: this.$t('vouchers.openDispute'),
          callback: () => this.setQueryParam(this.queryParam.ACTION, this.queryParam.OPEN_DISPUTE),
          permission: this.detailedVoucher.permissions.openDispute,
          condition:
            !this.detailedVoucherIsInDunningTypeDispute &&
            this.permissions.voucherDetails.openDispute
        },
        {
          param: this.queryParam.CLOSE_DISPUTE,
          component: CloseDisputeDialog,
          icon: 'icon-dispute',
          text: this.$t('vouchers.closeDispute'),
          callback: () => this.setQueryParam(this.queryParam.ACTION, this.queryParam.CLOSE_DISPUTE),
          active: true,
          permission: this.detailedVoucher.permissions.closeDispute,
          condition:
            this.detailedVoucherIsInDunningTypeDispute &&
            this.permissions.voucherDetails.closeDispute
        },
        {
          param: this.queryParam.OPEN_DELAY,
          component: OpenDelayDialog,
          icon: 'icon-delay',
          text: this.$t('vouchers.openDelay'),
          callback: () => this.setQueryParam(this.queryParam.ACTION, this.queryParam.OPEN_DELAY),
          permission: this.detailedVoucher.permissions.openDelay,
          condition:
            !this.detailedVoucherIsInDunningTypeDelay && this.permissions.voucherDetails.openDelay
        },
        {
          param: this.queryParam.CLOSE_DELAY,
          component: CloseDelayDialog,
          icon: 'icon-delay',
          text: this.$t('vouchers.closeDelay'),
          callback: () => this.setQueryParam(this.queryParam.ACTION, this.queryParam.CLOSE_DELAY),
          active: true,
          permission: this.detailedVoucher.permissions.closeDelay,
          condition:
            this.detailedVoucherIsInDunningTypeDelay && this.permissions.voucherDetails.closeDelay
        },
        {
          param: this.queryParam.EDIT_COMMENT,
          component: EditCommentDialog
        },
        {
          param: this.queryParam.SHOW_BALANCING_SUMMARY,
          component: BalancingSummary,
          permission: this.detailedVoucher.permissions.showBalancingSummary
        }
      ];
    },
    openDebtRemaining() {
      return (
        this.detailedVoucher.leftoverAmount > 0 &&
        !this.detailedVoucher.children.some((voucher) => voucher.type === 'annulation')
      );
    },
    detailedVoucherIsBlocked() {
      return this.detailedVoucher.dunningStatus === 'blocked';
    },
    detailedVoucherIsInDunningTypeDispute() {
      return this.detailedVoucher.dunningType === DunningType.DISPUTE;
    },
    detailedVoucherIsInDunningTypeDelay() {
      return this.detailedVoucher.dunningType === DunningType.DELAY;
    },
    fetchedDetailedVoucher() {
      return (
        this.startedInitialFetch &&
        !this.loadingVouchers &&
        !this.loadingDetails &&
        !!this.detailedVoucher
      );
    },
    showDetailsDrawer() {
      return this.$route.params.id !== undefined;
    },
    defaultFilter() {
      return {
        type: this.invoiceTypesWithUniqueTranslations
      };
    },
    invoiceTypesWithUniqueTranslations() {
      const invoiceTypes = INVOICE_TYPES.filter((type) =>
        Object.values(this.product.voucherTypeGroups).some((group) => group.includes(type))
      );

      return invoiceTypes.reduce((uniqueTypes, newType) => {
        if (!uniqueTypes.some((type) => formatVoucherType(type) === formatVoucherType(newType))) {
          uniqueTypes.push(newType);
        }
        return uniqueTypes;
      }, []);
    },
    reportData() {
      if (!this.detailedVoucher) {
        return;
      }

      return this.detailedVoucher.isInvoice
        ? `Belegdaten:
        * Identifier: ${this.detailedVoucher.id}
        * OBN: ${this.detailedVoucher.officialVoucherNumber || '-'}
        * SAP-Nr.: ${this.detailedVoucher.sapNumber || '-'}`
        : `Belegdaten:
        * Identifier: ${this.detailedVoucher.id}
        * OBN: ${this.detailedVoucher.officialVoucherNumber || '-'}
        * SAP-Nr.: ${this.detailedVoucher.sapNumber || '-'}

        Zugehörige Rechnung:
        * Identifier: ${this.detailedVoucher.parent?.id || '-'}
        * OBN: ${this.detailedVoucher.parent?.officialVoucherNumber || '-'}`;
    }
  },

  methods: {
    ...mapMutations(NAMESPACE, {
      resetDetailedVoucher: 'RESET_DETAILED_VOUCHER'
    }),

    ...mapActions(NAMESPACE, [
      'fetchVouchers',
      'fetchDetailedVoucher',
      'fetchCSV',
      'fetchFilterBnetIdentifier',
      'fetchFilterVoucherNumbers',
      'fetchFilterEncashmentFileNumbers'
    ]),

    async updateDetailedVoucher() {
      if (!this.$route.params.id && this.detailedVoucher) {
        return this.resetDetailedVoucher();
      }

      if (!this.$route.params.id) {
        return;
      }

      await this.fetchDetailedVoucher({
        identifier: this.$route.params.id,
        fetchOpenWorkQueue: this.permissions.voucherDetails.openWorkQueue
      });
    },

    loadVouchers(query = this.defaultQuery) {
      this.fetchVouchers({
        page: query[QueryParam.PAGE],
        rowsPerPage: query[QueryParam.ITEMS_PER_PAGE],
        filters: this.buildFilters(),
        sorting: this.buildSorting(query),
        fetchAmounts: this.permissions.vouchers.sum
      });
    },

    loadCSV() {
      return this.fetchCSV({
        page: 1,
        rowsPerPage: this.vouchersCount ?? 0,
        filters: this.buildFilters(),
        sorting: this.buildSorting(this.$route.query)
      });
    },

    // eslint-disable-next-line sonarjs/cognitive-complexity
    buildFilters() {
      let filters = {};
      let identifiers = [];

      if (this.customNumberSelection.length > 0) {
        identifiers = this.identifiersByNumbers(this.customNumberSelection);
      }

      if (this.bnetIdentifierSelection.length > 0) {
        identifiers = [
          ...new Set([
            ...identifiers,
            ...this.identifiersByBnetIdentifiers(this.bnetIdentifierSelection)
          ])
        ];
      }

      if (this.encashmentFileNumberSelection.length > 0) {
        identifiers = [
          ...new Set([
            ...identifiers,
            ...this.identifiersByEncashmentFileNumbers(this.encashmentFileNumberSelection)
          ])
        ];
      }

      if (identifiers.length > 0) {
        filters.identifier = identifiers;
      }

      if (this.statusSelection.length > 0) {
        filters.statusToShow = this.statusSelection;
      }

      if (this.locationSelection.length > 0) {
        filters.location = this.locationSelection;
      }

      if (this.voucherDateSelection.length > 0) {
        filters.voucherDate = {
          start: this.voucherDateSelection[0][0] / 1000 ?? null,
          end: this.voucherDateSelection[0][1] / 1000 ?? null
        };
      }

      if (this.dueDateSelection.length > 0) {
        filters.paymentDeadline = {
          start: this.dueDateSelection[0][0] / 1000 ?? null,
          end: this.dueDateSelection[0][1] / 1000 ?? null
        };
      }

      if (this.uploadDateSelection.length > 0) {
        filters.uploadDate = {
          start: this.uploadDateSelection[0][0] / 1000 ?? null,
          end: this.uploadDateSelection[0][1] / 1000 ?? null
        };
      }

      if (this.typeSelection.length > 0) {
        filters.type = this.typeSelection.flatMap(
          (type) => this.product.voucherTypeGroups[type] ?? []
        );
      }

      if (this.purchaseStatusSelection.length > 0) {
        filters.purchaseStatus = this.purchaseStatusSelection.flatMap(
          (status) => this.purchaseStatusGroups[status] ?? []
        );
      }

      return Object.keys(filters).length > 0 ? filters : undefined;
    },

    reloadDetails(query) {
      this.updateDetailedVoucher();
      this.loadVouchers(query);
    }
  },

  watch: {
    '$route.params.id'() {
      this.updateDetailedVoucher();
    },
    '$route.query': {
      deep: true,
      handler(query) {
        if (!this.startedInitialFetch) {
          return;
        }

        this.updateTableQueries(query, this.queryValues, this.loadVouchers);
      }
    }
  },

  async created() {
    if (
      this.customNumberSelection.length > 0 ||
      this.bnetIdentifierSelection.length > 0 ||
      this.encashmentFileNumberSelection.length > 0
    ) {
      await Promise.all([
        this.fetchFilterBnetIdentifier(),
        this.fetchFilterVoucherNumbers(),
        this.fetchFilterEncashmentFileNumbers()
      ]);
    } else {
      this.fetchFilterBnetIdentifier();
      this.fetchFilterVoucherNumbers();
      this.fetchFilterEncashmentFileNumbers();
    }

    this.loadVouchers();
    this.updateDetailedVoucher();
    this.startedInitialFetch = true;
  },

  destroyed() {
    if (this.detailedVoucher) {
      this.resetDetailedVoucher();
    }
  }
};
</script>
