<template>
  <v-container fluid pa-5 pt-10>
    <v-row class="mt-5" no-gutters justify="space-between" align="center">
      <v-col cols="auto">
        <h3 class="font-weight-bold">
          {{ details.type | formatVoucherType }}
          {{ details.numberToShow }}
        </h3>
      </v-col>
      <v-col cols="auto">
        <edit-comment-btn :comment="details.comment" v-bind="$props" v-on="$listeners" />
      </v-col>
    </v-row>
    <v-row v-if="details.location" class="caption" dense>
      <v-col cols="auto">{{ $t('debtor') }}:</v-col>
      <v-col class="d-flex align-center">
        <app-link-btn :title="details.location.name" :to="getLocationRoute(details.location)" />
      </v-col>
    </v-row>
    <v-row v-else-if="details.debtor" class="caption" dense>
      <v-col cols="auto">{{ $t('debtor') }}:</v-col>
      <v-col class="d-flex align-center">
        <app-link-btn :title="details.debtor.name" :to="getDebtorRoute(details.debtor)" />
      </v-col>
    </v-row>

    <v-row class="mt-4" justify="space-between" align="stretch">
      <v-col>
        <h3>
          {{ details.statusToShow | formatVoucherStatusToShow }}
        </h3>
        <app-status-chip
          v-if="!hasStatusClosed(details)"
          :value="details.subStatus ? details.subStatus : 'accepted'"
        />
      </v-col>
      <v-col class="text-right">
        <h3>
          {{ details.amount | formatCurrency }}
        </h3>
        <div class="caption text--muted">{{ $t('amount') }}</div>
      </v-col>
    </v-row>

    <v-row justify="space-between">
      <v-col>
        <h3>
          {{ details.customNumber || '-' }}
        </h3>
        <div class="caption text--muted">{{ details.officialVoucherNumber || '-' }}</div>
      </v-col>
      <v-col class="text-right">
        <h3 class="semi-bold">
          {{ details.leftoverAmount | formatCurrency }}
        </h3>
        <div class="caption text--muted">{{ $t('vouchers.outstandingAmount') }}</div>
      </v-col>
    </v-row>

    <v-row v-if="permanentDebtInfo">
      <v-col cols="auto">
        <icon-permanent-debt />
      </v-col>
      <v-col class="subtitle-1">
        {{ $t('vouchers.permanentDebtInvoice') }}
        <div class="grey--text">
          {{ permanentDebtInfo }}
        </div>
        <app-link-btn
          variant="muted"
          title="navigation.toPermanentDebt"
          :to="getPermanentDebtRoute(details.permanentDebt)"
        />
      </v-col>
    </v-row>

    <v-row v-if="permissions.voucherDetails.openWorkQueue && details.workQueue.length > 0">
      <v-col>
        <detailed-work-queue-alert :queue="details.workQueue" />
      </v-col>
    </v-row>

    <v-row v-if="hasStatusFailed(details)">
      <v-col>
        <app-alert>{{ $t('technicalError') }}</app-alert>
      </v-col>
    </v-row>

    <v-row
      v-if="
        details.permissions.showBalancingSummary &&
        hasStatusClosed(details) &&
        details.balancingVoucherNumber
      "
    >
      <v-col>
        <balancing-alert />
      </v-col>
    </v-row>

    <v-row v-if="voucherIsNotPurchasable">
      <v-col>
        <purchase-status-alert :value="details.bnetPurchaseStatus" />
      </v-col>
    </v-row>

    <v-row v-if="!details.sapNumber">
      <v-col>
        <app-alert color="primary" icon="$iconInfo" text="vouchers.reviewInfo" />
      </v-col>
    </v-row>

    <app-action-buttons :actions="actions" />

    <app-tabs
      :key="details.id"
      v-model="activeTab"
      class="mt-8"
      :tabs="filterItemsWithFulfilledCondition(tabs)"
    >
      <component
        :is="filterItemsWithFulfilledCondition(tabs)[activeTab].component"
        class="mt-4"
        v-bind="tabs[activeTab].data"
      />
    </app-tabs>

    <div v-if="hasAdminView">
      <v-divider style="margin-top: 5rem" />
      <v-row>
        <v-col>
          <h3>{{ details.type }}</h3>
          <span class="caption text--muted">{{ $t('vouchers.voucherType') }}</span>
        </v-col>
        <v-col class="text-right">
          <h3>{{ details.documentId || '-' }}</h3>
          <span class="caption text--muted">{{ $t('documentIdentifier') }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3 v-if="details.bnetStatus">
            ({{ details.bnetStatus }})
            {{ details.bnetStatus | formatBnetStatus }}
          </h3>
          <h3 v-else>-</h3>
          <span class="caption text--muted">{{ $t('bnetStatus') }}</span>
        </v-col>
        <v-col class="text-right">
          <h3>{{ details.bnetId || '-' }}</h3>
          <span class="caption text--muted">{{ $t('bnetIdentifier') }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3>{{ details.officialVoucherNumber || '-' }}</h3>
          <span class="caption text--muted">{{ $t('vouchers.officialVoucherNumber') }}</span>
        </v-col>
        <v-col class="text-right">
          <h3>{{ details.sapNumber || '-' }}</h3>
          <span class="caption text--muted">{{ $t('sapNumber') }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3>{{ details.userEmail || '-' }}</h3>
          <span class="caption text--muted">{{ $t('vouchers.uploadUser') }}</span>
        </v-col>
        <v-col class="text-right">
          <template v-if="details.serviceFee">
            <h3>{{ details.serviceFee.amount | formatCurrency }}</h3>
            <span class="caption text--muted">
              {{ $t('serviceFees.fee') }} ({{ details.serviceFee.serviceFeeMatrix.sapId }})
            </span>
          </template>
          <template v-else>
            <h3>-</h3>
            <span class="caption text--muted">{{ $t('serviceFees.fee') }}</span>
          </template>
        </v-col>
      </v-row>

      <process-log :data="processLog" />
      <app-api-log :data="detailedVoucherRaw" api-name="voucher/show/{identifier}" />
      <app-api-log
        v-if="processLogRaw"
        :data="formattedProcessLog"
        :api-name="`Process-Log: ${$tc('resultsCounted', processLogRaw.length)}`"
      />
    </div>
  </v-container>
</template>

<script>
import BalancingAlert from '@/modules/Vouchers/components/BalancingAlert';
import DetailedWorkQueueAlert from '@/modules/Vouchers/components/Details/DetailedWorkQueueAlert';
import EditCommentBtn from '@/modules/Vouchers/components/EditCommentBtn';
import FormattedProcessLogMixin from '@/mixins/FormattedProcessLogMixin';
import HistoryMixin from '@/mixins/HistoryMixin';
import ProcessLog from '@/shared/components/ProcessLog';
import PurchaseStatusAlert from '@/shared/components/PurchaseStatusAlert';
import StatusMixin from '@/mixins/StatusMixin';
import TabChildVouchers from '@/modules/Vouchers/components/Details/TabChildVouchers';
import TabVoucherHistory from '@/modules/Vouchers/components/Details/TabVoucherHistory';
import TabVoucherInfo from '@/modules/Vouchers/components/Details/TabVoucherInfo';
import formatDate from '@/helper/filter/formatDate';
import { NAMESPACE } from '@/modules/Vouchers/store';
import { mapState } from 'vuex';

export default {
  name: 'VoucherDetails',

  mixins: [FormattedProcessLogMixin, HistoryMixin, StatusMixin],

  components: {
    EditCommentBtn,
    PurchaseStatusAlert,
    BalancingAlert,
    ProcessLog,
    DetailedWorkQueueAlert
  },

  data: () => ({
    activeTab: 0
  }),

  props: {
    details: {
      type: Object,
      default: () => ({})
    },
    voucherActions: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapState(NAMESPACE, ['processLog', 'processLogRaw', 'detailedVoucherRaw']),
    permanentDebtInfo() {
      return this.details.permanentDebt
        ? this.$t('vouchers.permanentDebtInvoiceInfo', {
            number: this.details.permanentDebt.customNumber,
            date: formatDate(this.details.voucherDate)
          })
        : '';
    },
    actions() {
      return [
        this.queryParam.DIRECT_PAYMENT,
        this.queryParam.CREDIT_ADVICE,
        this.queryParam.ANNULATION,
        this.queryParam.ABANDONMENT,
        this.queryParam.SET_DUNNING_BLOCK,
        this.queryParam.CANCEL_DUNNING_BLOCK,
        this.queryParam.OPEN_DISPUTE,
        this.queryParam.CLOSE_DISPUTE,
        this.queryParam.OPEN_DELAY,
        this.queryParam.CLOSE_DELAY
      ].map((query) => this.voucherActions.find((action) => action.param === query));
    },
    tabs() {
      return [
        {
          id: 1,
          name: this.$t('history'),
          badge: this.history.length,
          component: TabVoucherHistory,
          data: { history: this.history }
        },
        {
          id: 2,
          name: this.$t('voucherDetails'),
          component: TabVoucherInfo,
          data: { details: this.details }
        },
        {
          id: 3,
          name: this.$t('vouchers.relatedVouchers'),
          component: TabChildVouchers,
          data: { details: this.details }
        }
      ];
    }
  },

  watch: {
    'details.id'() {
      this.activeTab = 0;
    }
  }
};
</script>
