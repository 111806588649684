<template>
  <div>
    <v-divider />
    <v-timeline v-if="data.length > 0" dense align-top>
      <v-timeline-item v-for="item in data" :key="item.id" color="secondary" fill-dot>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon v-if="item.levelName === 'ERROR'" color="error" class="mr-2">
                mdi-alert
              </v-icon>
              <div :class="{ 'error--text': item.levelName === 'ERROR' }">
                {{ item.updated }} - {{ item.context }} - {{ item.levelName }}
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="pa-0">
              <v-list style="overflow-x: auto">
                <v-list-item v-for="(value, key) in item" :key="key" class="pa-0">
                  <pre>{{ key }}: {{ value }}</pre>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-timeline-item>
    </v-timeline>
    <div v-else class="mt-3">{{ $t('noProcessLogResults') }}</div>
    <v-divider />
  </div>
</template>

<script>
export default {
  name: 'ProcessLog',

  props: {
    data: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped>
.v-expansion-panels {
  margin-left: -1rem;
  flex: 1;
}

.v-expansion-panel-header {
  word-break: break-word;
  max-height: 6rem;
  overflow: hidden;
}

.v-expansion-panel-content__wrap .v-list-item {
  min-height: auto;
  user-select: auto;
}

>>> .v-timeline-item,
.v-timeline--dense:not(.v-timeline--reverse):before {
  margin-left: -2rem;
  align-items: center;
}

>>> .v-timeline-item__body {
  display: flex;
}

>>> .v-timeline-item__dot {
  height: 0.625rem;
  width: 0.625rem;
  align-self: center;
}

pre {
  font-size: 0.85rem;
}

>>> .time-wrapper {
  display: inline-block;
  min-width: 125px;
}
</style>
