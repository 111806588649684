<template>
  <child-side-drawer-container>
    <template #header>
      <v-row justify="space-between">
        <v-col cols="auto">
          <h3>{{ details.parent ? details.parent.numberToShow : '-' }}</h3>
          <span class="caption text--muted">{{ $t('invoice') }}</span>
        </v-col>
        <v-col cols="auto" class="text-right">
          <h3 v-if="details.parent">
            {{ details.parent.amount | formatCurrency }}
          </h3>
          <h3 v-else>-</h3>
          <span class="caption text--muted">{{ $t('vouchers.invoiceAmount') }}</span>
        </v-col>
      </v-row>
    </template>

    <v-row class="mt-2" no-gutters justify="space-between" align="center">
      <v-col cols="auto">
        <h3 class="font-weight-bold">
          {{ details.type | formatVoucherType }}
          {{ details.numberToShow }}
        </h3>
      </v-col>
      <v-col cols="auto">
        <edit-comment-btn :comment="details.comment" v-bind="$props" v-on="$listeners" />
      </v-col>
    </v-row>
    <v-row v-if="details.parent" class="caption" dense>
      <v-col cols="auto">{{ $t('vouchers.referenceVoucher') }}:</v-col>
      <v-col class="d-flex align-center">
        <app-link-btn :title="details.parent.numberToShow" :to="getVoucherRoute(details.parent)" />
      </v-col>
    </v-row>
    <v-row v-if="details.location" class="caption" dense>
      <v-col cols="auto">{{ $t('debtor') }}:</v-col>
      <v-col class="d-flex align-center">
        <app-link-btn :title="details.location.name" :to="getLocationRoute(details.location)" />
      </v-col>
    </v-row>
    <v-row v-else-if="details.debtor" class="caption" dense>
      <v-col cols="auto">{{ $t('debtor') }}:</v-col>
      <v-col class="d-flex align-center">
        <app-link-btn :title="details.debtor.name" :to="getDebtorRoute(details.debtor)" />
      </v-col>
    </v-row>

    <v-row class="mt-4" justify="space-between">
      <v-col>
        <h3>{{ details.statusToShow | formatVoucherStatusToShow }}</h3>
        <app-status-chip
          v-if="!hasStatusClosed(details)"
          class="mt-1 d-inline-flex"
          :value="details.subStatus ? details.subStatus : 'accepted'"
        />
      </v-col>
      <v-col class="text-right">
        <h3>
          {{ details.amount | formatCurrency }}
        </h3>
        <span class="caption text--muted">{{ $t('amount') }}</span>
      </v-col>
    </v-row>

    <v-row v-if="hasStatusFailed(details)">
      <v-col>
        <app-alert>{{ $t('technicalError') }}</app-alert>
      </v-col>
    </v-row>

    <v-row
      v-if="
        details.permissions.showBalancingSummary &&
        hasStatusClosed(details) &&
        details.balancingVoucherNumber
      "
    >
      <v-col>
        <balancing-alert />
      </v-col>
    </v-row>

    <app-tabs
      :key="details.id"
      v-model="activeTab"
      class="mt-8"
      :tabs="filterItemsWithFulfilledCondition(tabs)"
    >
      <component
        :is="filterItemsWithFulfilledCondition(tabs)[activeTab].component"
        class="mt-4"
        v-bind="tabs[activeTab].data"
      />
    </app-tabs>

    <div v-if="hasAdminView" class="px-5">
      <v-divider style="margin-top: 5rem" />
      <v-row>
        <v-col>
          <h3>{{ details.bnetId || '-' }}</h3>
          <span class="caption text--muted">{{ $t('bnetIdentifier') }}</span>
        </v-col>
        <v-col class="text-right">
          <h3>{{ details.documentId || '-' }}</h3>
          <span class="caption text--muted">{{ $t('documentIdentifier') }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3 v-if="details.bnetStatus">
            ({{ details.bnetStatus }})
            {{ details.bnetStatus | formatBnetStatus }}
          </h3>
          <h3 v-else>-</h3>
          <span class="caption text--muted">{{ $t('bnetStatus') }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3>{{ details.officialVoucherNumber || '-' }}</h3>
          <span class="caption text--muted">{{ $t('vouchers.officialVoucherNumber') }}</span>
        </v-col>
        <v-col class="text-right">
          <h3>{{ details.sapNumber || '-' }}</h3>
          <span class="caption text--muted">{{ $t('sapNumber') }}</span>
        </v-col>
      </v-row>

      <process-log :data="processLog" />
      <app-api-log :data="detailedVoucherRaw" api-name="voucher/show/{identifier}" />
      <app-api-log
        v-if="processLogRaw"
        :data="formattedProcessLog"
        :api-name="`Process-Log: ${$tc('resultsCounted', processLogRaw.length)}`"
      />
    </div>
  </child-side-drawer-container>
</template>

<script>
import { mapState } from 'vuex';
import { NAMESPACE } from '@/modules/Vouchers/store';
import ChildSideDrawerContainer from '@/shared/components/ChildSideDrawerContainer';
import TabVoucherHistory from '@/modules/Vouchers/components/Details/TabVoucherHistory';
import TabVoucherInfo from '@/modules/Vouchers/components/Details/TabVoucherInfo';
import FormattedProcessLogMixin from '@/mixins/FormattedProcessLogMixin';
import ProcessLog from '@/shared/components/ProcessLog';
import BalancingAlert from '@/modules/Vouchers/components/BalancingAlert';
import StatusMixin from '@/mixins/StatusMixin';
import HistoryMixin from '@/mixins/HistoryMixin';
import EditCommentBtn from '@/modules/Vouchers/components/EditCommentBtn';

export default {
  name: 'ChildVoucherDetails',

  mixins: [FormattedProcessLogMixin, HistoryMixin, StatusMixin],

  components: {
    EditCommentBtn,
    ChildSideDrawerContainer,
    BalancingAlert,
    ProcessLog
  },

  data: () => ({
    activeTab: 0
  }),

  props: {
    details: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    ...mapState(NAMESPACE, ['processLog', 'processLogRaw', 'detailedVoucherRaw']),
    tabs() {
      return [
        {
          id: 1,
          name: this.$t('history'),
          badge: this.history.length,
          component: TabVoucherHistory,
          data: { history: this.history }
        },
        {
          id: 2,
          name: this.$t('voucherDetails'),
          component: TabVoucherInfo,
          data: { details: this.details }
        }
      ];
    }
  },

  watch: {
    'details.id'() {
      this.activeTab = 0;
    }
  }
};
</script>
