<template>
  <app-alert color="primary" icon="$iconInfo">
    {{ $t('vouchers.purchaseStatusInfo') }}
    <div class="mt-4">
      {{ $t(`vouchers.bnetPurchaseStatusOptions.${value}`) }}
    </div>
  </app-alert>
</template>

<script>
export default {
  name: 'PurchaseStatusAlert',

  props: {
    value: {
      type: Number,
      default: 0
    }
  }
};
</script>
