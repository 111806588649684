<template>
  <app-text-btn
    :class="{ 'file-link-btn': true, 'file-link-btn--highlighted': highlighted }"
    :disabled="disabled"
    :underlined="!disabled"
    target="_blank"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span
      v-if="prependIcon"
      :class="{
        'file-link-btn__icon-wrapper': true,
        'file-link-btn__icon-wrapper--prepend': !!title || !!$slots.default
      }"
    >
      <component :is="icon" :style="{ '--size': iconSizeVar }" />
    </span>
    <slot>{{ $te(title) ? $t(title) : title }}</slot>
    <span
      v-if="appendIcon"
      :class="{
        'file-link-btn__icon-wrapper ': true,
        'file-link-btn__icon-wrapper--append': !!title || !!$slots.default
      }"
    >
      <component :is="icon" :style="{ '--size': iconSizeVar }" />
    </span>
  </app-text-btn>
</template>

<script>
export default {
  name: 'FileLinkBtn',

  props: {
    appendIcon: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    highlighted: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'icon-file-pdf'
    },
    iconSize: {
      type: [Number, String],
      default: '16'
    },
    prependIcon: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },

  computed: {
    iconSizeVar() {
      return isNaN(this.iconSize) ? this.iconSize : this.iconSize + 'px';
    }
  }
};
</script>

<style scoped lang="scss">
@mixin icon-styles($size) {
  ::v-deep .v-icon,
  ::v-deep svg {
    width: var(--size, $size);
    height: var(--size, $size);
    font-size: var(--size, $size);
  }
}

.file-link-btn {
  @include icon-styles(1.125rem);

  &.v-size--small {
    @include icon-styles(1rem);
  }

  &.v-size--large {
    @include icon-styles(1.25rem);
  }

  &--highlighted {
    @include caption-font-style;
    color: var(--c-button-link);

    svg {
      height: 28px;
      width: 28px;
    }

    .file-link-btn__icon-wrapper {
      color: var(--c-text);
    }
  }

  ::v-deep .v-btn__content {
    align-items: flex-start;
    word-wrap: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.file-link-btn__icon-wrapper {
  vertical-align: middle;

  &--prepend {
    margin-right: 8px;
  }

  &--append {
    margin-left: 8px;
  }
}
</style>
