<template>
  <v-container class="px-0">
    <app-labeled-data text-right no-gutters label="vouchers.voucherDate">
      {{ details.voucherDate | formatDate }}
    </app-labeled-data>
    <app-labeled-data text-right no-gutters label="contact">
      <router-link v-if="details.location" :to="getLocationRoute(details.location)">
        {{ details.location.name }}
      </router-link>
      <router-link v-else-if="details.debtor" :to="getDebtorRoute(details.debtor)">
        {{ details.debtor.name }}
      </router-link>
      <span v-else>-</span>
    </app-labeled-data>
    <app-labeled-data v-if="details.isInvoice" text-right no-gutters label="vouchers.maturity">
      {{ details.paymentDeadline | formatDate }}
    </app-labeled-data>
    <app-labeled-data text-right no-gutters label="amount">
      {{ details.amount | formatCurrency }}
    </app-labeled-data>
    <app-labeled-data
      v-if="details.isInvoice"
      text-right
      no-gutters
      label="vouchers.leftoverAmount"
    >
      {{ details.leftoverAmount | formatCurrency }}
    </app-labeled-data>

    <app-labeled-data
      class="mt-8"
      text-right
      no-gutters
      label="vouchers.yourOriginalVoucherNumber"
      :value="details.customNumber"
    />
    <app-labeled-data
      text-right
      no-gutters
      label="vouchers.officialVoucherNumber"
      :value="details.officialVoucherNumber"
    />
    <app-labeled-data
      text-right
      no-gutters
      label="vouchers.customisedLabel"
      :value="details.customisedLabel"
    />
    <app-labeled-data
      v-if="details.encashmentFileNumber"
      text-right
      no-gutters
      label="vouchers.encashmentFileNumber"
      :value="details.encashmentFileNumber"
    />

    <v-row no-gutters class="mt-10">
      <v-col class="subtitle text-uppercase">{{ $t('files') }}</v-col>
    </v-row>
    <app-labeled-data text-right no-gutters label="vouchers.yourOriginalVoucher">
      <file-link-btn
        v-if="details.source"
        :loading="loadingFileType === 'originalVoucher'"
        :title="details.originalName || $t('pdf')"
        append-icon
        @click="openFile(details.source, 'originalVoucher', details.originalName)"
      />
      <span v-else>-</span>
    </app-labeled-data>
    <app-labeled-data text-right no-gutters label="vouchers.officialVoucher">
      <file-link-btn
        v-if="details.officialVoucherSource"
        :loading="loadingFileType === 'officialVoucher'"
        :title="`${$t('vouchers.officialVoucher')}.pdf`"
        append-icon
        @click="
          openFile(
            details.officialVoucherSource,
            'officialVoucher',
            `${$t('vouchers.officialVoucher')}.pdf`
          )
        "
      />
      <span v-else>-</span>
    </app-labeled-data>
    <app-labeled-data text-right no-gutters label="attachment">
      <file-link-btn
        v-if="details.attachment"
        :loading="loadingFileType === 'attachment'"
        :title="details.attachment.fileName || $t('pdf')"
        append-icon
        @click="openFile(details.attachment.source, 'attachment', details.attachment.fileName)"
      />
      <span v-else>-</span>
    </app-labeled-data>
  </v-container>
</template>

<script>
import FileLinkBtn from '@/shared/components/FileLinkBtn';
import OpenFileMixin from '@/mixins/OpenFileMixin';

export default {
  name: 'TabVoucherInfo',

  components: { FileLinkBtn },

  mixins: [OpenFileMixin],

  props: {
    details: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
