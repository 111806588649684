<template>
  <app-select
    filter
    :items="states"
    :label="$t('vouchers.voucherType')"
    label-icon="icon-vouchers"
    multiple
    hide-details
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'VoucherTypeSelect',

  computed: {
    states() {
      return Object.keys(this.product.voucherTypeGroups)
        .filter((type) => this.product.voucherTypeGroups[type]?.length > 0)
        .map((type) => ({
          label: this.$t(`vouchers.voucherTypes.${type}`),
          value: type
        }));
    }
  }
};
</script>
