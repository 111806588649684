<template>
  <v-container class="side-drawer--intended" fluid pa-0>
    <div class="pa-5 pb-1 mt-5">
      <slot name="header"></slot>
    </div>

    <div class="elevation-8 ml-3 pa-5 white flex-fill">
      <slot></slot>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'ChildSideDrawerContainer'
};
</script>

<style scoped>
.side-drawer--intended {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--c-grey-background);
}

.side-drawer--intended span {
  display: inherit;
}
</style>
