import BnetPurchaseAlertBlackList from '@/statics/bnetPurchaseAlertBlackList';

export default {
  computed: {
    voucherIsNotPurchasable() {
      return (
        this.isFactoring &&
        this.details.bnetPurchaseStatus > 1 &&
        BnetPurchaseAlertBlackList.includes(this.details.bnetPurchaseStatus) === false
      );
    }
  },

  methods: {
    hasStatusClosed(voucher) {
      return this.product.voucherStatusToShowGroups.closed.includes(voucher.statusToShow);
    },
    hasStatusFailed(voucher) {
      return this.product.voucherStatusToShowGroups.failed.includes(voucher.statusToShow);
    },
    hasStatusPending(voucher) {
      return this.product.voucherStatusToShowGroups.pending.includes(voucher.statusToShow);
    }
  }
};
