<template>
  <filter-wrapper :has-active-filters="hasActiveFilters" @reset="resetAllFilter">
    <v-row dense>
      <v-col cols="12" sm="4" md="3" lg="2">
        <status-select v-model="statusSelection" />
      </v-col>
      <v-col v-if="isFactoring" cols="12" sm="4" md="3" lg="2">
        <purchase-status-select v-model="purchaseStatusSelection" />
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <location-select v-model="locationSelection" />
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <time-periods-select
          :voucher-date.sync="voucherDateSelection"
          :upload-date.sync="uploadDateSelection"
          :due-date.sync="dueDateSelection"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <voucher-type-select v-model="typeSelection" />
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <voucher-number-select v-model="customNumberSelection" />
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <encashment-file-number-select v-model="encashmentFileNumberSelection" />
      </v-col>
      <v-col v-if="hasAdminView" cols="12" sm="4" md="3" lg="2">
        <bnet-identifier-select v-model="bnetIdentifierSelection" />
      </v-col>
    </v-row>

    <template :slot="isMobile ? 'active-filters' : 'default'">
      <v-row v-if="!isMobile || hasActiveFilters" dense>
        <v-col cols="12" class="pt-3 pb-4">
          <active-filter-chip
            v-for="(selection, index) in statusSelection"
            :key="selection"
            v-model="statusSelection"
            :index="index"
            icon="icon-gauge"
          >
            <span v-if="$te(`vouchers.statusToShowOptions.${selection}`)">
              {{ selection | formatVoucherStatusToShow }}
            </span>
            <span v-else>{{ selection | formatSubStatus }}</span>
          </active-filter-chip>
          <template v-if="isFactoring">
            <active-filter-chip
              v-for="(selection, index) in purchaseStatusSelection"
              :key="selection"
              v-model="purchaseStatusSelection"
              :index="index"
              icon="icon-money"
            >
              {{ $t(`vouchers.purchaseStatusOptions.${selection}`) }}
            </active-filter-chip>
          </template>
          <active-filter-chip
            v-for="(selection, index) in locationSelection"
            :key="selection"
            v-model="locationSelection"
            :index="index"
            icon="icon-clients"
          >
            {{ selection }}
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in voucherDateSelection"
            :key="'voucherDate_' + selection"
            v-model="voucherDateSelection"
            :index="index"
            icon="icon-calendar"
          >
            {{ $t('vouchers.voucherDate') }}:
            <template v-if="!selection[0]">{{ $t('to') }} {{ selection[1] | formatDate }}</template>
            <template v-else-if="!selection[1]">
              {{ $t('from') }} {{ selection[0] | formatDate }}
            </template>
            <template v-else>
              {{ selection[0] | formatDate }}
              <template v-if="selection[0] !== selection[1]">
                - {{ selection[1] | formatDate }}
              </template>
            </template>
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in dueDateSelection"
            :key="'dueDate_' + selection"
            v-model="dueDateSelection"
            :index="index"
            icon="icon-calendar"
          >
            {{ $t('vouchers.dueDate') }}:
            <template v-if="!selection[0]">{{ $t('to') }} {{ selection[1] | formatDate }}</template>
            <template v-else-if="!selection[1]">
              {{ $t('from') }} {{ selection[0] | formatDate }}
            </template>
            <template v-else>
              {{ selection[0] | formatDate }}
              <template v-if="selection[0] !== selection[1]">
                - {{ selection[1] | formatDate }}
              </template>
            </template>
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in uploadDateSelection"
            :key="'uploadDate_' + selection"
            v-model="uploadDateSelection"
            :index="index"
            icon="icon-calendar"
          >
            {{ $t('vouchers.uploadDate') }}:
            <template v-if="!selection[0]">{{ $t('to') }} {{ selection[1] | formatDate }}</template>
            <template v-else-if="!selection[1]">
              {{ $t('from') }} {{ selection[0] | formatDate }}
            </template>
            <template v-else>
              {{ selection[0] | formatDate }}
              <template v-if="selection[0] !== selection[1]">
                - {{ selection[1] | formatDate }}
              </template>
            </template>
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in typeSelection"
            :key="selection"
            v-model="typeSelection"
            :index="index"
            icon="icon-vouchers"
          >
            {{ selection | formatVoucherType }}
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in customNumberSelection"
            :key="selection"
            v-model="customNumberSelection"
            :index="index"
            icon="icon-hash"
          >
            {{ $t('vouchers.customNumber') }}: {{ selection }}
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in encashmentFileNumberSelection"
            :key="selection"
            v-model="encashmentFileNumberSelection"
            :index="index"
          >
            {{ $t('encashmentFileNumber') }}: {{ selection }}
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in bnetIdentifierSelection"
            :key="selection"
            v-model="bnetIdentifierSelection"
            :index="index"
          >
            {{ $t('bnetIdentifier') }}: {{ selection }}
          </active-filter-chip>
          <app-text-btn
            v-if="hasActiveFilters && !isMobile"
            color="background-text"
            class="caption"
            title="resetFilter"
            @click="resetAllFilter"
          />
        </v-col>
      </v-row>
    </template>
  </filter-wrapper>
</template>

<script>
import ActiveFilterChip from '@/shared/components/ActiveFilterChip';
import BnetIdentifierSelect from '@/modules/Vouchers/components/Filter/BnetIdentifierSelect';
import EncashmentFileNumberSelect from '@/modules/Vouchers/components/Filter/EncashmentFileNumberSelect';
import FilterWrapper from '@/shared/components/FilterWrapper';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import LocationSelect from '@/modules/Vouchers/components/Filter/LocationSelect';
import PurchaseStatusSelect from '@/modules/Vouchers/components/Filter/PurchaseStatusSelect';
import StatusSelect from '@/modules/Vouchers/components/Filter/StatusSelect';
import TimePeriodsSelect from '@/modules/Vouchers/components/Filter/TimePeriodsSelect';
import VoucherNumberSelect from '@/modules/Vouchers/components/Filter/VoucherNumberSelect';
import VoucherTypeSelect from '@/shared/components/VoucherTypeSelect';

export default {
  name: 'VouchersFilter',

  mixins: [ListFilterMixin],

  components: {
    ActiveFilterChip,
    BnetIdentifierSelect,
    EncashmentFileNumberSelect,
    FilterWrapper,
    LocationSelect,
    PurchaseStatusSelect,
    StatusSelect,
    TimePeriodsSelect,
    VoucherNumberSelect,
    VoucherTypeSelect
  }
};
</script>
