<template>
  <div>
    <app-select
      filter
      :label="$t('timePeriod')"
      content-class="time-select__menu"
      label-icon="icon-calendar"
      hide-details
      dense
      solo
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #prepend-item>
        <ValidationObserver ref="form" tag="form">
          <v-container>
            <v-row dense>
              <v-col>
                <app-select
                  v-model="dateType"
                  :items="dateTypeOptions"
                  filter
                  hide-details
                  dense
                  solo
                />
              </v-col>
            </v-row>
            <v-row :key="dateType" dense>
              <v-col>
                {{ $t('of') }}
                <app-date-picker v-model="start" name="of" placeholder="dateHint" append-icon />
              </v-col>
              <v-col>
                {{ $t('to') }}
                <app-date-picker
                  v-model="end"
                  :min="minEndDate"
                  :rules="start ? `min_date:${minEndDate}` : ''"
                  name="to"
                  placeholder="dateHint"
                  append-icon
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col v-for="(option, index) in quickSelectOptions" :key="index" cols="auto">
                <app-link-btn
                  :title="option.label"
                  variant="muted"
                  @click="setQuickSelect(option.value)"
                />
              </v-col>
            </v-row>
            <v-row dense class="mt-4">
              <v-col>
                <app-btn min-height="30" class="subtitle-1" block @click="filterItems">
                  {{ $t('filter') }}
                </app-btn>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </template>
    </app-select>
  </div>
</template>

<script>
import { formatDateToTimestamp, formatTimestampToDate } from '@/helper/filter/formatDate';
import {
  MILLISECONDS_PER_MONTH,
  MILLISECONDS_PER_QUARTER,
  MILLISECONDS_PER_WEEK,
  MILLISECONDS_PER_YEAR
} from '@/statics/timePeriods';

export default {
  name: 'TimePeriodsSelect',

  props: {
    voucherDate: {
      type: Array,
      default: () => []
    },
    uploadDate: {
      type: Array,
      default: () => []
    },
    dueDate: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    dateType: '',
    start: '',
    end: '',
    showMenu: false
  }),

  computed: {
    dateTypeOptions() {
      return ['voucherDate', 'dueDate', 'uploadDate'].map((dateType) => ({
        label: this.$t(`vouchers.${dateType}`),
        value: dateType
      }));
    },
    currentSelection() {
      // [periodStartInMs, periodEndInMs] or null
      return this.$props[this.dateType][0] && this.$props[this.dateType][0].length > 0
        ? this.$props[this.dateType][0]
        : null;
    },
    minEndDate() {
      if (!this.start) {
        return;
      }

      return this.start;
    },
    quickSelectOptions() {
      return this.dateType === 'dueDate' ? this.dueDatePeriodOptions : this.timePeriodOptions;
    },
    timePeriodOptions() {
      return [
        {
          label: this.$t('timePeriodOptions.today'),
          value: [Date.now(), Date.now()]
        },
        {
          label: this.$t('timePeriodOptions.lastWeek'),
          value: [Date.now() - MILLISECONDS_PER_WEEK, Date.now()]
        },
        {
          label: this.$t('timePeriodOptions.lastMonth'),
          value: [Date.now() - MILLISECONDS_PER_MONTH, Date.now()]
        },
        {
          label: this.$t('timePeriodOptions.lastQuarter'),
          value: [Date.now() - MILLISECONDS_PER_QUARTER, Date.now()]
        },
        {
          label: this.$t('timePeriodOptions.lastYear'),
          value: [Date.now() - MILLISECONDS_PER_YEAR, Date.now()]
        }
      ];
    },
    dueDatePeriodOptions() {
      return [
        {
          label: this.$t('dueDatePeriodOptions.nextQuarter'),
          value: [Date.now(), Date.now() + MILLISECONDS_PER_QUARTER]
        },
        {
          label: this.$t('dueDatePeriodOptions.nextMonth'),
          value: [Date.now(), Date.now() + MILLISECONDS_PER_MONTH]
        },
        {
          label: this.$t('dueDatePeriodOptions.nextWeek'),
          value: [Date.now(), Date.now() + MILLISECONDS_PER_WEEK]
        },
        {
          label: this.$t('dueDatePeriodOptions.today'),
          value: [Date.now(), Date.now()]
        },
        {
          label: this.$t('dueDatePeriodOptions.sinceLastWeek'),
          value: [Date.now() - MILLISECONDS_PER_WEEK, Date.now()]
        },
        {
          label: this.$t('dueDatePeriodOptions.sinceLastMonth'),
          value: [Date.now() - MILLISECONDS_PER_MONTH, Date.now()]
        },
        {
          label: this.$t('dueDatePeriodOptions.sinceLastQuarter'),
          value: [Date.now() - MILLISECONDS_PER_QUARTER, Date.now()]
        },
        {
          label: this.$t('dueDatePeriodOptions.sinceLastYear'),
          value: [Date.now() - MILLISECONDS_PER_YEAR, Date.now()]
        }
      ];
    }
  },

  watch: {
    dateType() {
      // set already selected time period as default
      if (this.currentSelection) {
        this.start = this.currentSelection[0]
          ? formatTimestampToDate(this.currentSelection[0] / 1000)
          : '';
        this.end = this.currentSelection[1]
          ? formatTimestampToDate(this.currentSelection[1] / 1000)
          : '';
        return;
      }

      this.start = '';
      this.end = '';
    }
  },

  methods: {
    async filterItems() {
      // return if start is past end date or current selection is already active
      if (
        !(await this.$refs.form.validate()) ||
        (this.currentSelection &&
          String(this.currentSelection[0]) === String(formatDateToTimestamp(this.start) * 1000) &&
          String(this.currentSelection[1]) === String(formatDateToTimestamp(this.end) * 1000))
      ) {
        return;
      }

      if (!this.start && !this.end) {
        return this.$emit(`update:${this.dateType}`, null);
      }

      this.$emit(`update:${this.dateType}`, [
        [
          this.start ? formatDateToTimestamp(this.start) * 1000 : null,
          this.end ? formatDateToTimestamp(this.end) * 1000 : null
        ]
      ]);
    },

    setQuickSelect([startInMs, endInMs]) {
      this.start = formatTimestampToDate(startInMs / 1000);
      this.end = formatTimestampToDate(endInMs / 1000);
      this.filterItems();
    }
  },

  created() {
    this.dateType = this.dateTypeOptions[0].value;
  }
};
</script>

<style>
.time-select__menu {
  width: 400px;
}

.time-select__menu .v-list-item {
  display: none;
}
</style>
