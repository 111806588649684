<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form">
    <app-dialog
      v-model="open"
      primary-action-text="vouchers.closeDispute"
      secondary-action-text="navigation.cancel"
      title="vouchers.closeDispute"
      :disabled="failed"
      :loading="loading"
      @submit="submitCloseDispute"
    >
      <p>{{ $t('vouchers.dispute.infoClose') }}</p>

      <app-input-row class="mt-4" label="description">
        <app-textarea v-model="formData.message" name="description" rows="3" required />
      </app-input-row>

      <app-input-row label="attachment">
        <app-file-input
          v-model="formData.document"
          name="attachment"
          placeholder="choosePDF"
          prepend-inner-icon
          align-right
          rules="ext:pdf"
        />
      </app-input-row>

      <app-input-row class="mt-8 mt-sm-4">
        <app-checkbox
          v-model="confirmCloseDispute"
          label="vouchers.dispute.confirmCloseDispute"
          hide-details
          required
        />
      </app-input-row>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.toVoucher"
      title="vouchers.dispute.disputeClosed"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </ValidationObserver>
</template>

<script>
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Vouchers/store';

export default {
  name: 'CloseDisputeDialog',

  mixins: [ShowDialogMixin],

  props: {
    voucher: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    formData: {
      message: '',
      attachment: null
    },
    confirmCloseDispute: false
  }),

  methods: {
    ...mapActions(NAMESPACE, ['closeDispute']),

    async submitCloseDispute() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      let formData = new FormData();
      formData.append('disputeMessage', this.formData.message);
      formData.append('disputeDocument', this.formData.attachment);

      this.loading = true;
      const { error } = await this.closeDispute({
        voucherIdentifier: this.$route.params.id,
        formData
      });
      this.loading = false;

      if (error) {
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
