<template>
  <v-alert
    v-if="unhandledWorkQueueCount > 0"
    border="left"
    colored-border
    color="error"
    elevation="2"
    class="py-3 px-5 mt-2"
  >
    <v-row align="center" no-gutters>
      <v-col class="grow">
        {{ $tc('workQueue.openDecisionsCounted', unhandledWorkQueueCount) }}
      </v-col>
      <v-col class="shrink">
        <app-btn :to="{ name: routeName.WORK_QUEUE }">{{ $t('navigation.view') }}</app-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import { mapState } from 'vuex';
import { NAMESPACE } from '@/modules/User/store';

export default {
  name: 'WorkQueueAlert',

  computed: {
    ...mapState(NAMESPACE, ['unhandledWorkQueueCount'])
  }
};
</script>

<style scoped>
>>> .v-alert__border {
  border-left-width: 5px;
  border-right-width: 0;
}
</style>
